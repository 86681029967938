// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as Utils from "../../libs/Utils.js";

var _map = {"vessel":"vessel","section":"section","structural":"structural","safety":"safety","structural_component":"structural_component","safety_component":"safety_component","sire":"sire","sire_chapter":"sire_chapter","company_form":"company_form","checklist":"checklist","checklist_part":"checklist_part","PIQ":"PIQ","risk4sea_checklist":"risk4sea_checklist","risk4sea_checklist_item":"risk4sea_checklist_item"};

function tToJs(param) {
  return param;
}

function tFromJs(param) {
  return _map[param];
}

var deserFromJS = $$JSON.Field.variadicString("Expected a valid Level Type Kind", tFromJs);

var $$Set = Utils.MakeStringTypeSet({});

var fromString = tFromJs;

var toString = tToJs;

export {
  tToJs ,
  tFromJs ,
  deserFromJS ,
  fromString ,
  toString ,
  $$Set ,
}
/* deserFromJS Not a pure module */
