// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as RRule from "../../libs/RRule.js";
import * as Luxon from "luxon";
import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import * as LevelId from "./LevelId.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as VesselId from "../vessel/VesselId.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReIndexed from "@kaiko.io/rescript-reindexed/lib/es6/src/ReIndexed.js";
import * as Completion from "../common/Completion.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/lib/es6/src/Core__Array.js";
import * as LevelTypeId from "./LevelTypeId.js";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as LevelSubmission from "./LevelSubmission.js";
import * as MarkdownDocumentId from "../markdown/MarkdownDocumentId.js";

var Definition = {};

var null_parent_id = Caml_option.some(LevelId.$$null);

var null_guidance_document_id = Caml_option.some(MarkdownDocumentId.$$null);

var $$null = {
  id: LevelId.$$null,
  parent_id: null_parent_id,
  vessel_id: VesselId.$$null,
  guidance_document_id: null_guidance_document_id,
  name: "",
  path: "",
  number: undefined,
  depth: -1,
  recurrence: undefined,
  due_date: undefined,
  overdue_date: undefined,
  ignore_overdue_status: true,
  kind: LevelTypeId.$$null,
  risk_status: undefined
};

var Defaults = {
  $$null: $$null
};

var include = ReIndexed.MakeIdModel(Definition, {});

function ofVessel(vesselId) {
  return {
          TAG: "Is",
          _0: "vessel_id",
          _1: vesselId
        };
}

function childrenOf(parent) {
  return {
          TAG: "Is",
          _0: "parent_id",
          _1: parent
        };
}

function root(vesselId) {
  return {
          TAG: "And",
          _0: {
            TAG: "Is",
            _0: "depth",
            _1: 1
          },
          _1: {
            TAG: "Is",
            _0: "vessel_id",
            _1: vesselId
          }
        };
}

function ofKinds(kindIds) {
  return {
          TAG: "AnyOf",
          _0: "kind",
          _1: kindIds
        };
}

function branch(path) {
  var paths = Curry._3(Prelude.$$Array.rangeBy, 4, path.length, 4).map(function (length) {
        return path.substr(0, length);
      });
  return {
          TAG: "AnyOf",
          _0: "path",
          _1: paths
        };
}

function chapters(vesselId) {
  return {
          TAG: "And",
          _0: {
            TAG: "Is",
            _0: "depth",
            _1: 2
          },
          _1: {
            TAG: "Is",
            _0: "vessel_id",
            _1: vesselId
          }
        };
}

function getOverdueText(level, questions) {
  var today = new Date();
  var overdue_dates = Curry._2(Prelude.$$Array.keepMap, Curry._2(Prelude.$$Array.keep, Curry._1(Prelude.$$Array.concatMany, questions.map(function (q) {
                    return q.levels;
                  })), (function (l) {
              return l.level_path.startsWith(level.path);
            })), (function (l) {
          return l.overdue_date;
        }));
  var minDate = Prelude.default(Prelude.$$Array.first(overdue_dates), today);
  var overdue_date = overdue_dates.reduce((function (a, b) {
          if (Caml_obj.lessthan(a, b)) {
            return a;
          } else {
            return b;
          }
        }), minDate);
  var due_dates = Curry._2(Prelude.$$Array.keepMap, Curry._2(Prelude.$$Array.keep, Curry._1(Prelude.$$Array.concatMany, questions.map(function (q) {
                    return q.levels;
                  })), (function (l) {
              return l.level_path.startsWith(level.path);
            })), (function (l) {
          return l.due_date;
        }));
  var minDate$1 = Prelude.default(Prelude.$$Array.first(due_dates), today);
  var due_date = due_dates.reduce((function (a, b) {
          if (Caml_obj.lessthan(a, b)) {
            return a;
          } else {
            return b;
          }
        }), minDate$1);
  return Completion.Status.getDueTextFromDates(level.ignore_overdue_status, Prelude.default(level.due_date, due_date), Prelude.default(level.overdue_date, overdue_date));
}

function byName(level) {
  return level.name;
}

var _map = {"recurrent":"recurrent","oneOff":"oneOff"};

function type_ToJs(param) {
  return param;
}

function type_FromJs(param) {
  return _map[param];
}

function getType(level) {
  if (Curry._1(Prelude.OptionExported.$$Option.isSome, level.recurrence)) {
    return "recurrent";
  } else {
    return "oneOff";
  }
}

function expectedSubmissions(level, submissions, draftSubmissionIds) {
  var recurrence = level.recurrence;
  if (recurrence === undefined) {
    return [];
  }
  var recurrence$1 = Caml_option.valFromOption(recurrence);
  var rset = RRule.toSet(recurrence$1);
  var today = new Date();
  var submissions$1 = Prelude.$$Array.sort(submissions, LevelSubmission.byTargetDate, undefined);
  var harmonizationDate = RRule.$$Set.harmonizationDate(rset);
  var oldestSubmission = Prelude.$$Array.first(submissions$1);
  var begining;
  if (oldestSubmission !== undefined) {
    begining = oldestSubmission.target_date;
  } else {
    var today$1 = Luxon.DateTime.fromJSDate(today);
    var interval = Luxon.Interval.fromDateTimes(Luxon.DateTime.fromJSDate(harmonizationDate), today$1);
    begining = interval.length("years") >= 1.0 ? today$1.minus({
              years: 1
            }).toJSDate() : harmonizationDate;
  }
  var begining$1 = Prelude.default(RRule.$$Set.before(rset, begining, true), begining);
  var end = Prelude.default(RRule.$$Set.after(rset, today, undefined), today);
  var occurrences = rset.between(begining$1, end, true);
  var number = {
    contents: 0
  };
  return Curry._1(Prelude.$$Array.concatMany, Curry._2(Prelude.$$Array.zip, occurrences, Curry._2(Prelude.$$Array.sliceToEnd, occurrences, 1)).map(function (param) {
                  var until = param[1];
                  var since = param[0];
                  var submissions$2 = Curry._2(Prelude.$$Array.keep, submissions$1, (function (submission) {
                          if (Caml_obj.lessequal(since, submission.target_date)) {
                            return Caml_obj.lessthan(submission.target_date, until);
                          } else {
                            return false;
                          }
                        }));
                  if (submissions$2.length !== 0) {
                    return submissions$2.map(function (submission) {
                                number.contents = number.contents + 1 | 0;
                                return {
                                        id: number.contents,
                                        start_date: since,
                                        end_date: until,
                                        recurrence: recurrence$1,
                                        submission: submission,
                                        finished: !Prelude.$$Array.includes(draftSubmissionIds, submission.id)
                                      };
                              });
                  } else {
                    number.contents = number.contents + 1 | 0;
                    return [{
                              id: number.contents,
                              start_date: since,
                              end_date: until,
                              recurrence: recurrence$1,
                              submission: undefined,
                              finished: false
                            }];
                  }
                }));
}

function split(input) {
  var regex = /\d+|\D+/g;
  return Core__Array.keepSome(Core__Option.getOr(Caml_option.nullable_to_opt(input.match(regex)), [])).map(function (token) {
              var n = Curry._1(Prelude.Int.fromString, token);
              if (n !== undefined) {
                return n;
              } else {
                return token;
              }
            });
}

function cmp(level1, level2) {
  var a1 = split(level1.name);
  var a2 = split(level2.name);
  var _l1 = Belt_List.fromArray(a1);
  var _l2 = Belt_List.fromArray(a2);
  while(true) {
    var l2 = _l2;
    var l1 = _l1;
    if (!l1) {
      if (l2) {
        return -1;
      } else {
        return 0;
      }
    }
    if (!l2) {
      return 1;
    }
    var l2$1 = l2.tl;
    var h2 = l2.hd;
    var l1$1 = l1.tl;
    var h1 = l1.hd;
    if (typeof h1 === "string") {
      if (typeof h2 !== "string") {
        return -1;
      }
      if (h1 !== h2) {
        if (h1 < h2) {
          return -1;
        } else {
          return 1;
        }
      }
      _l2 = l2$1;
      _l1 = l1$1;
      continue ;
    }
    if (typeof h2 === "string") {
      return 1;
    }
    if (h1 !== h2) {
      if (h1 < h2) {
        return -1;
      } else {
        return 1;
      }
    }
    _l2 = l2$1;
    _l1 = l1$1;
    continue ;
  };
}

function sorted(levels) {
  return Belt_SortArray.stableSortBy(levels, cmp);
}

var NaturalSort = {
  cmp: cmp,
  sorted: sorted
};

var NaturalSortComparator = Belt_Id.MakeComparableU(NaturalSort);

var Identifier;

var RiskStatus;

var steplen = 4;

var clear = include.clear;

var save = include.save;

var $$delete = include.$$delete;

var remove = include.remove;

var IdComparator = include.IdComparator;

var Id = include.Id;

export {
  Identifier ,
  RiskStatus ,
  steplen ,
  Definition ,
  Defaults ,
  clear ,
  save ,
  $$delete ,
  remove ,
  IdComparator ,
  Id ,
  ofVessel ,
  childrenOf ,
  root ,
  ofKinds ,
  branch ,
  chapters ,
  getOverdueText ,
  byName ,
  type_ToJs ,
  type_FromJs ,
  getType ,
  expectedSubmissions ,
  NaturalSort ,
  NaturalSortComparator ,
}
/* include Not a pure module */
